$(function() {
	var $mainContainer = $('.main')
	var $mainNavigation = $('.navigation-main')
	var $navigationToggle = $('.navigation-toggle')
	var $navigationMobile = $('.navigation-mobile')
	var $navigationMobileClose = $('.navigation-mobile-close')
	var isTransparent = $mainContainer.hasClass('with-transparent-header')

	$(document).on('scroll', function() {
		var scrollTop = $(window).scrollTop();

		if ($mainNavigation.hasClass('is-transparent') && scrollTop >= 0) {
			$mainNavigation.removeClass('is-transparent')
		} else if (isTransparent && $mainContainer.hasClass('with-transparent-header') && !$mainNavigation.hasClass('is-transparent') && scrollTop === 0) {
			$mainNavigation.addClass('is-transparent')
		}

		if (scrollTop > 0) {
			$mainNavigation.addClass('has-scrolled')
		}

		if (scrollTop === 0 && $mainNavigation.hasClass('has-scrolled')) {
			$mainNavigation.removeClass('has-scrolled')
		}
	})

	$navigationToggle.on('click', function () {
		$navigationMobile.toggleClass('is-active')
	})

	$navigationMobileClose.on('click', function () {
		$navigationMobile.removeClass('is-active')
	})

	$(document).trigger('scroll')
});