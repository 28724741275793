$(function () {
	var calendar = document.getElementById('calendar')
	
	if (calendar) {
		window['moment-range'].extendMoment(moment);

		var disabledRanges = $('#calendar')
		.data('disabledDates')
		.map(function (date) {
			return moment.range(moment(date.start, 'DD-MM-YYYY').startOf('day'), moment(date.end, 'DD-MM-YYYY').endOf('day'))
		})

		var field = document.getElementById('calendar');
		var picker = new Pikaday({
			disableDayFn: function (date) {
				var givenDate = moment(date)
				return disabledRanges.some(range => range.contains(givenDate))
			},
		});
		calendar.parentNode.insertBefore(picker.el, field.nextSibling);
	}
})