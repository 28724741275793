$(function () {
	var $galleryItems = $('.photo-gallery').find('a')

	$galleryItems.on('click', function (e) {
		e.preventDefault()
		if ($(this)[0].tagName !== 'A') return

		var pswpElement = document.querySelectorAll('.pswp')[0]
		var $photoGallery = $($(this).parents('.photo-gallery')[0])
		var $photoGalleryItems = $photoGallery.find('a')
		var galleryItemIndex = $photoGalleryItems.index($(this))

		var items = $photoGalleryItems.map(function (index, element) {
			var src = $(this).attr('href')
			var dataSize = $(this).data('size')
			var width = dataSize.split('x')[0]
			var height = dataSize.split('x')[1]

			return {
				src: src,
				w: width,
				h: height
			}
		}).get()

		// define options (if needed)
		var options = {
				// optionName: 'option value'
				// for example:
				index: galleryItemIndex, // start at first slide
				showHideOpacity: true
		};

		// Initializes and opens PhotoSwipe
		var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
		gallery.init();
	})
})