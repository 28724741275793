$(function () {
	var $heroSliders = $('.hero-slider');
	var $heroSliderContainer = $('.hero-slider-container');
	var $heroSliderDown = $('.hero-slider-down');

	// Initialize sliders
	$heroSliders.slick({
		infinite: true,
		speed: 500,
		fade: true,
		cssEase: 'linear',
		autoplay: true,
		autoplaySpeed: 8000
	})

	$heroSliderDown.on('click', function () {
		const heroSliderContainerHeight = $heroSliderContainer.height()
		const navigationHeight = $('.navigation-main').height()
		$('html, body').animate({ scrollTop: heroSliderContainerHeight - navigationHeight + 52 }, 600);
	})
})